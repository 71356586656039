import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import {Backdrop, Box, Grid} from "@mui/material";
import PageSkeleton from "../PageSkeleton";
import {apiPost} from "../authentication/OAuth2Utils";
import {useViewport} from "../hooks/ViewportContext";
import {Image} from "@mui/icons-material";

const Home = ({
    user = null,
    userImpersonator = null,
    dispatch
}) => {
    const [started,setStarted] = useState(false)
    const [sizeLandscapeImage,setSizeLandscapeImage] = useState({
        width : 0,
        height : 0
    })
    const [sizePortraitImage,setSizePortraitImage] = useState({
        width : 0,
        height : 0
    })

    const {width, height, size} = useViewport()
    const orientation = ['lg','xl'].includes(size) ? 'landscape' : 'portrait'

    useEffect(() => {
        setStarted(true);
    }, [true]);



    const sceneWidth = ['xl','lg'].includes(size) ?
            width - 275
        :
        size === 'md' ?
            width - 60
            :
            width

    const displayHeight = orientation === 'landscape' ?
        Math.round(sceneWidth * sizeLandscapeImage.height / sizeLandscapeImage.width)
        :
        Math.round(sceneWidth * sizePortraitImage.height / sizePortraitImage.width)

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            dispatch={dispatch}
            page_active="home"
        >

                {/*<Box*/}
                {/*    sx={{*/}
                {/*        position : 'fixed',*/}
                {/*        height: height,*/}
                {/*        backgroundColor : 'white',*/}
                {/*        opacity : started ? 0 : 1,*/}
                {/*        transition: 'opacity .7s',*/}
                {/*        transitionDelay: '1.5s',*/}
                {/*        pointerEvents: 'none',*/}
                {/*        textAlign: 'center',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <img*/}
                {/*        alt={'Innoclair logo'}*/}
                {/*        src='/img/bruneelHeadLogo.jpeg'*/}
                {/*        style={{*/}
                {/*            width : '25%',*/}
                {/*            backgroundColor: 'white',*/}
                {/*            marginTop : height/2-150*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</Box>*/}

            {

                <>
                    <img
                        alt={'preload landscape background'}
                        src={"/img/bruneelAccueil.webp"}
                        style={{"display" : "none"}}
                        onLoad={(e) => {
                            setSizeLandscapeImage({
                                width : e.currentTarget.naturalWidth,
                                height : e.currentTarget.naturalHeight,
                            })
                        }}
                    />
                    <img
                        alt={'preload portrait background'}
                        src={"/img/bruneelAccueil.webp"}
                        style={{"display" : "none"}}
                        onLoad={(e) => {
                            setSizePortraitImage({
                                width : e.currentTarget.naturalWidth,
                                height : e.currentTarget.naturalHeight,
                            })
                        }}
                    />
                    {
                        orientation === 'landscape' ?
                            <Grid container>
                                <Grid
                                    item xs={12}
                                    sx={{
                                        backgroundImage: 'url(/img/bruneelAccueil.webp)',
                                        backgroundSize: '100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPositionX: 0,
                                        height: height-50
                                    }}
                                >
                                </Grid>
                            </Grid>
                        :
                            <Grid container>
                                <Grid
                                    item xs={12}
                                    sx={{
                                        backgroundColor : '#42BDDB',
                                        backgroundImage: 'url(/img/bruneelAccueil.webp)',
                                        backgroundSize: '100%',
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPositionX: '40%',
                                        height: height-50
                                    }}
                                >
                                </Grid>
                            </Grid>
                    }
                </>
            }
        </PageSkeleton>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator
    }),
)(Home)
