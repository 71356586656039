import React, {useEffect, useState} from "react"
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {connect} from "react-redux";
import {
    Button,
    Container, Dialog, DialogTitle,
    Divider,
    Grid, IconButton, InputAdornment, Link, List, ListItem, ListItemIcon, ListItemText,
    Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {apiPost} from "../authentication/OAuth2Utils";
import {useNavigate, useParams} from "react-router-dom";
import {changePageLoadingState, PageLoader} from "../PageLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid, light, brands, regular} from "@fortawesome/fontawesome-svg-core/import.macro";
import {setPdfReaderUrl} from "../dialogs/PdfReaderDialog";
import {useViewport} from "../hooks/ViewportContext";
import {reformatDate} from "../Utils";
import {range} from "ramda";
import {getCsv} from "../TableUtils";


const Item = ({
    user = null,
    userImpersonator = null,
    pageLoading = null,
    dispatch
}) => {
    const [object, setObject] = useState(null)
    const [details, setDetails] = useState(null)
    const [movements, setMovements] = useState(null)
    const navigate = useNavigate()
    const params = useParams()

    const {size} = useViewport()

    const init = () => {
        dispatch(setMessageInformation(null))
        dispatch(changePageLoadingState(true))
        apiPost('/api/get-item',params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setObject(response.data)
                }
            })
            .finally(() => {
                dispatch(changePageLoadingState(false))
            })

        apiPost('/api/get-item-details',params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setDetails(response.data)
                }
            })

        apiPost('/api/get-item-movements',params)
            .then(response => {
                if(response.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: response.message
                    }))
                } else {
                    setMovements(response.data)
                }
            })
    }

    useEffect(() => {
        init()
    },[])

    return (
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title="Détail article"
            page_active="stock"
            loading = {pageLoading}
            dispatch={dispatch}
        >
            {
                object ?
                    <Container
                        sx={{
                            marginTop : '10px'
                        }}
                    >
                        <Paper sx={{padding : '1em',marginBottom : '1em'}}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <Typography variant={'h4'}>
                                        <FontAwesomeIcon icon={light('box-taped')} style={{paddingRight:'.5em'}}/>
                                        {object.RealItemCode} - {object.ItemName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{marginY:'.5em'}}>
                                <Grid item xs={12} md={6}>
                                    <FontAwesomeIcon icon={light('cubes')} style={{paddingRight:'.5em'}}/>
                                    En stock : {object.QuantityOnStock}
                                </Grid>
                            </Grid>
                            <Grid container spacing={2} sx={{marginTop:'1em'}}>
                                <Grid item xs={12} md={5} >
                                    <Grid container justifyContent={'space-between'} alignItems={'center'}
                                          sx={{marginBottom:'.5em'}}
                                    >
                                        <Grid item xs={'auto'}>
                                            <Typography
                                                variant={'h5'}
                                            >
                                                Détail des lots
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Button
                                                variant={'outlined'}
                                                startIcon={<FontAwesomeIcon icon={light('table')}></FontAwesomeIcon>}
                                                onClick={() => {
                                                    getCsv(
                                                        {
                                                            freeSearch:'',
                                                            searchColumn:'',
                                                            orderBy:'',
                                                            order:''
                                                        },
                                                        {
                                                            url : 'download-csv/item-details',
                                                            fileName :'DetailsArticle'+object.RealItemCode+'.csv',
                                                            params
                                                        }
                                                    )
                                                }}
                                            >
                                                Exporter
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Numéro de lot</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>Date d'entrée</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        details ?
                                                            details.map((obj, key) =>
                                                                <TableRow key={key}>
                                                                    <TableCell>{obj.BatchId}</TableCell>
                                                                    <TableCell>{reformatDate(obj.InDate)}</TableCell>
                                                                </TableRow>
                                                            )
                                                            :
                                                            range(1,10).map((obj) =>
                                                                <TableRow key={obj}>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                </TableRow>
                                                            )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Grid>
                                <Grid item xs={12} md={7}>

                                    <Grid container justifyContent={'space-between'} alignItems={'center'}
                                          sx={{marginBottom:'.5em'}}
                                    >
                                        <Grid item xs={'auto'}>
                                            <Typography
                                                variant={'h5'}
                                            >
                                                Historique des mouvements
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Button
                                                variant={'outlined'}
                                                startIcon={<FontAwesomeIcon icon={light('table')}></FontAwesomeIcon>}
                                                onClick={() => {
                                                    getCsv(
                                                        {
                                                            freeSearch:'',
                                                            searchColumn:'',
                                                            orderBy:'',
                                                            order:''
                                                        },
                                                        {
                                                            url : 'download-csv/item-movements',
                                                            fileName :'MouvementsArticle'+object.RealItemCode+'.csv',
                                                            params
                                                        }
                                                    )
                                                }}
                                            >
                                                Exporter
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    {
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Typography>Date</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>Entrée</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>Sortie</Typography>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Typography>Solde</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        movements ?
                                                            movements.map((obj, key) =>
                                                                <TableRow key={key}>
                                                                    <TableCell>{reformatDate(obj.DocDate)}</TableCell>
                                                                    <TableCell>{obj.Entree}</TableCell>
                                                                    <TableCell>{obj.Sortie}</TableCell>
                                                                    <TableCell>{obj.Solde}</TableCell>
                                                                </TableRow>
                                                            )
                                                            :
                                                            range(1,10).map((obj) =>
                                                                <TableRow key={obj}>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                    <TableCell><Skeleton/></TableCell>
                                                                </TableRow>
                                                            )
                                                    }
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    }
                                </Grid>
                            </Grid>
                        </Paper>
                    </Container>
                : null
            }
        </PageSkeleton>
    )
}



export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        pageLoading: state.pageLoaderReducer.pageLoading
    }),
)(Item)
