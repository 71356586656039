import React, {useEffect, useState, Fragment} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {useLocation} from "react-router-dom";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Box,
    Grid,
    Stack,
} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {useViewport} from "../hooks/ViewportContext";
import dayjs from "dayjs";

const StockDate = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    partnerCode = null,
    dispatch
}) => {
    const [stockLoading,setStockLoading] = useState(false)
    const [stock, setStock] = useState(null)
    const [stockDate, setStockDate] = useState(dayjs())

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const {width, height, size} = useViewport()

    const fetchData = () => {
        dispatch(setMessageInformation(null))
        setStockLoading(true)
        apiPost(
            '/api/get-stock-date',
            {
                'date': stockDate.format('YYYY-MM-DD'),
                partnerCode
            },
            0,
        )
            .then(data => {
                if (data.status === 'error') {
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setStock(data.data)
                }
            })
            .finally(() => {
                setStockLoading(false)
            })

    }

    useEffect(() => {
        if (stockDate.isValid()){
            fetchData()
        }
    }, [stockDate,partnerCode]);

    return(
        <>
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={'Stock à date'}
            page_active={uri}
            loading={loading}
            caption={caption}
            dispatch={dispatch}
        >
            <Stack
                direction={['xs','sm'].includes(size) ? 'column' : 'row'}
                sx={
                    ['xs','sm'].includes(size) ?
                        {
                            display:'flex',
                            flexDirection:'column',
                            height : height - (125),
                            maxHeight : height - (125)
                        }
                    :
                        {display:'flex'}
                }
            >
                <Box
                    sx={
                        ['xs','sm'].includes(size) ?
                            {
                                flexBasis:'auto',
                                flexGrow:'0',
                                flexShrink:'3',
                                overflow:'auto'
                            }
                        :
                            {
                                overflow: 'auto',
                                height: height - 125,
                                flexBasis: '100%'
                            }
                    }
                >
                    {
                        stock ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableData
                                        columns={stock.entete}
                                        data={stock.data}
                                        defaultOrder='asc'
                                        defaultOrderColumnId='RealItemCode'
                                        loading={loading}
                                        caption={caption}
                                        dispatch={dispatch}
                                        user={user}
                                        notFixed={true}
                                        page_active={location.pathname.substring(1)}
                                        stockDate={stockDate}
                                        setStockDate={setStockDate}
                                        csvExportInfo={{
                                            url : 'download-csv/stock-date',
                                            fileName :'StockDate'+stockDate.format(' DD-MM-YYYY')+'.csv',
                                            params : {
                                                date : stockDate.format('YYYY-MM-DD')
                                            }
                                        }}
                                        tableLoading={stockLoading}
                                        skeleton={<TableSkeleton nbCol={3} nbLig={10} />}
                                        partnerCode={partnerCode}
                                    ></TableData>
                                </Grid>

                            </Grid>
                        :
                            <TableSkeleton nbCol={3} nbLig={10} />
                    }
                </Box>
            </Stack>
        </PageSkeleton>
    </>
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
        partnerCode: state.partnerReducer.partnerCode,
    }),
)(StockDate)
