import React, {useEffect, useState, Fragment} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {useLocation} from "react-router-dom";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Box,
    Grid,
    Stack,
} from "@mui/material";
import TableUtils, {TableData, TableSkeleton} from "../TableUtils";
import {useViewport} from "../hooks/ViewportContext";

const Stock = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    dispatch,
    partnerCode,
}) => {
    const [stockLoading,setStockLoading] = useState(false)
    const [stock, setStock] = useState(null)

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const {width, height, size} = useViewport()

    const fetchData = () => {
        dispatch(setMessageInformation(null))
        setStockLoading(true)
        apiPost('/api/get-stock', {partnerCode})
        .then(data => {
            if(data.status === 'error'){
                dispatch(setMessageInformation({
                    severity: 'error',
                    title: 'Une erreur est survenue',
                    content: data.message
                }))
            } else {
                setStock(data.data)
                if (data.data.data.length === 0) {
                    dispatch(setMessageInformation({
                        severity: 'info',
                        title: 'Information',
                        content: 'Il n\'y a aucun élément à afficher'
                    }))
                }
            }
        })
        .finally(() => {
            setStockLoading(false)
        })
    }

    useEffect(() => {
        fetchData()
    }, [partnerCode]);

    return(
        <>
            <PageSkeleton
                user={user}
                userImpersonator={userImpersonator}
                title={'Stock tps réel'}
                page_active={uri}
                loading={loading}
                caption={caption}
                dispatch={dispatch}
            >
                <Stack
                    direction={['xs','sm'].includes(size) ? 'column' : 'row'}
                    sx={
                        ['xs','sm'].includes(size) ?
                            {
                                display:'flex',
                                flexDirection:'column',
                                height : height - (125),
                                maxHeight : height - (125)
                            }
                        :
                            {display:'flex'}
                    }
                >
                    <Box
                        sx={
                            ['xs','sm'].includes(size) ?
                                {
                                    flexBasis:'auto',
                                    flexGrow:'0',
                                    flexShrink:'3',
                                    overflow:'auto'
                                }
                            :
                                {
                                    overflow: 'auto',
                                    height: height - 125,
                                    flexBasis: '100%'
                                }
                        }
                    >
                        {
                            stock ?
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TableData
                                            columns={stock.entete}
                                            data={stock.data}
                                            defaultOrder='asc'
                                            defaultOrderColumnId='RealItemCode'
                                            loading={loading}
                                            caption={caption}
                                            dispatch={dispatch}
                                            user={user}
                                            notFixed={true}
                                            page_active={location.pathname.substring(1)}
                                            csvExportInfo={{
                                                url : 'download-csv/stock',
                                                fileName :'Stock.csv'
                                            }}
                                            tableLoading={stockLoading}
                                            skeleton={<TableSkeleton nbCol={4} nbLig={10} />}
                                            partnerCode={partnerCode}
                                        ></TableData>
                                    </Grid>
                                </Grid>
                            :
                                <TableSkeleton nbCol={4} nbLig={10} />
                        }
                    </Box>
                </Stack>
            </PageSkeleton>
        </>
    )
}
export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
        partnerCode: state.partnerReducer.partnerCode,
    }),
)(Stock)


