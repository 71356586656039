import React, {Fragment, useEffect, useState} from "react"
import { styled } from '@mui/material/styles';
import {
    alpha,
    Box,
    Button,
    IconButton,
    Link,
    ButtonGroup,
    Avatar,
    Grid,
    Chip,
    InputAdornment,
    InputBase,
    MenuItem,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Tooltip,
    Checkbox,
    FormGroup,
    Collapse,
    TextField,
    FormControlLabel,
    Switch,
    ToggleButtonGroup,
    InputLabel,
    FormControl, ToggleButton, Autocomplete, Popper
} from "@mui/material";
import {Search as SearchIcon, Cancel as CancelIcon} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";
import {ValidateChip} from "./Buttons";
import {makeStyles} from "@mui/styles";
import {assocPath, clone} from "ramda";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {light, solid, thin, duotone,regular} from "@fortawesome/fontawesome-svg-core/import.macro";
import Typography from "@mui/material/Typography";
import {apiGet, apiGetFile} from "./authentication/OAuth2Utils";
import {useViewport} from "./hooks/ViewportContext";
import {getCardMetaData, reformatDate} from "./Utils";
import {PdfReaderSlice, setPdfReaderUrl} from "./dialogs/PdfReaderDialog";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import 'dayjs/locale/fr';
import Partners from "./Partners";

export const useStyles = makeStyles(theme => ({
    cellWithComment:{
        borderBottom: 'none !important'
    },
    cellWithoutComment: {
        borderBottom: 'none !important'
    }
}))


export const getCsv = (tableParams, csvExportInfo) => {
    const link = document.createElement("a");
    link.target = "_blank";
    link.download = csvExportInfo.fileName;
    let params = tableParams
    if (csvExportInfo?.params) {
        params = {...params,...csvExportInfo.params}
    }

    apiGetFile('/api/' + csvExportInfo.url, params)
        .then(data => {
            link.href = URL.createObjectURL(
                new Blob([data], {type: "text/csv"})
            );
            link.click();
        })
}


export const TableData = ({
    columns,
    data,
    defaultOrderColumnId=null,
    defaultOrder=null,
    csvExportInfo = {
        url : '', fileName : 'export'
    },
    page_active,
    dispatch,
    partnerCode,
    user,
    notFixed = null,
    handleSetCart,
    cart=null,
    stockDate,
    setStockDate,
    movementDateStart,
    setMovementDateStart,
    movementDateEnd,
    setMovementDateEnd,
    tableLoading,
    skeleton,
}) => {
    const [order, setOrder] = useState(defaultOrder === null ? 'desc' : defaultOrder)
    const [orderBy, setOrderBy] = useState(defaultOrderColumnId === null ? columns[0].id : defaultOrderColumnId)
    const [freeSearch, setFreeSearch] = useState('')
    const [searchColumn, setSearchColumn] = useState('ALL')
    const [filters, setFilters] = useState(null)
    const [searchActive, setSearchActive] = useState(false)
    const [searchTransitioning, setSearchTransitioning] = useState(false)
    const [collapseOpen, setCollapseOpen] = useState(null)

    const navigate = useNavigate()
    const location = useLocation()

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function descendingComparator(a, b, orderBy) {
        let varA = a[orderBy] ?? '';
        let varB = b[orderBy] ?? '';

        varA = typeof varA === 'object' ?
            varA?.title ? varA?.title : ''
        :
            varA;

        varB = typeof varB === 'object' ?
            varB?.title ? varB?.title : ''
        :
            varB;

        if (Object.prototype.toString.call(varA) === "[object String]") {
            return varB.localeCompare(varA);
        } else {
            if (varB < varA) {
                return -1;
            }
            if (varB > varA) {
                return 1;
            }
        }

        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const validateDisplayingData = (data, columns) => {
        if (filters){
            //parcours des filtres (checkbox avatar)
            let doNotDisplay = false;
            Object.keys(filters).forEach(function(key,index) {
                    //Si la valeur est répertoriée dans le filtre
                    if (!filters[key].hasOwnProperty(data[key]) || !filters[key][data[key]]){
                        //On filtre que si la valeur n'est pas "cochée" dans les filtres
                        doNotDisplay = true;
                    }
            });
            if (doNotDisplay){
                return false;
            }
        }

        // Soit on cherche en ET soit en OU
        if (freeSearch === '') {
            return true
        }
        // Si on se met en mode ET on commence à filterFreeSearch = true
        // Si on se met en mode OU on commence à filterFreeSearch = false
        let filterFreeSearch = true
        let freeSearches = freeSearch.trim().split(' ')
        freeSearches.map((word, index) => {
            let filterFreeSearchWord = false
            let regex = new RegExp(word, 'i')
            columns.map((column, index) => {
                if (searchColumn === 'ALL' || column.id === searchColumn) {
                    filterFreeSearchWord = filterFreeSearchWord || regex.test(data[column.id])
                }
            })
            // Si on se met en mode ET on effectue filterFreeSearch && filterFreeSearchWord
            // Si on se met en mode OU on effectue filterFreeSearch || filterFreeSearchWord
            filterFreeSearch = filterFreeSearch && filterFreeSearchWord
        })
        return filterFreeSearch
    }

    const setAction = (actionName, datum, actionParam = null) => {
        switch (actionName) {
            default :
                break;
        }
    }

    const downloadCsv = ({freeSearch, searchColumn, orderBy, order}) => {
        getCsv({freeSearch, searchColumn, orderBy, order}, csvExportInfo)
    }

    const handleCheck = ({column, value, state}) => {
        let clonedFilters = clone(filters)
        setFilters(assocPath([column,value],state,clonedFilters))
    }

    const initFilters = () => {
        let newFilters = null
        if (['stock', 'stock-date'].includes(page_active)){
            newFilters = {
                'FilterEnStock': {
                    'oui' : true,
                    'non' : false,
                }
            }
        }
        setFilters(newFilters)
    }

    const initCollapse = () => {
        let tempCollapse = []
        data.map(() => {
            tempCollapse.push(false)
        })
        setCollapseOpen(tempCollapse)
    }

    const toggleCollapseOpen = (index) => {
        let clonedCollapseOpen = clone(collapseOpen)
        clonedCollapseOpen[index] = !clonedCollapseOpen[index]
        setCollapseOpen(clonedCollapseOpen)
    }

    let nbColumnNotHidden = 0
    let lineForCounting = false

    const classes = useStyles()
    const {width, height, size} = useViewport()

    const cardMetaData = getCardMetaData(columns)

    useEffect(() => {
        initFilters()
        initCollapse()
    },[])

    return (
        <Box
        >
            <TableContainer component={Box}>
                <Grid
                    container
                    sx={{
                        padding : '10px'
                    }}
                    spacing={1}
                    alignItems={'center'}
                    justifyContent={
                        ['xs', 'sm'].includes(size)
                        ?
                            "flex-end"
                        :
                            "space-between"
                    }
                >
                    {
                        user?.roles.includes('ROLE_FULL') && user?.cardCode==='F00001' && csvExportInfo.url !== '' ?
                            <Grid item xs={'auto'}>
                                <Partners/>
                            </Grid>
                        : null
                    }
                    {
                        !['xs', 'sm'].includes(size) && csvExportInfo.url !== '' ?
                            <Grid item xs={'auto'}>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<FontAwesomeIcon icon={light('table')}></FontAwesomeIcon>}
                                    onClick={() => {
                                        downloadCsv({freeSearch, searchColumn, orderBy, order})
                                    }}
                                >
                                    Exporter
                                </Button>
                            </Grid>
                        : null
                    }
                    {
                        location.pathname === '/stock-date' ?
                            <Grid item xs={'auto'}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        disableFuture={true}
                                        slotProps={{
                                            textField:{
                                                size : 'small',
                                                readOnly : true
                                            }
                                        }}
                                        label="Date"
                                        onChange={(e) => {
                                            setStockDate(dayjs(e))
                                        }}
                                        value={stockDate ? stockDate : null}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            :
                            null
                    }
                    {
                        location.pathname === '/movements-date' ?
                            <Grid item xs={'auto'}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='fr'>
                                    <DatePicker
                                        disableFuture={true}
                                        slotProps={{
                                            textField:{
                                                size : 'small',
                                                readOnly : true
                                            }
                                        }}
                                        label="Date de début"
                                        onChange={(e) => {
                                            setMovementDateStart(dayjs(e))
                                        }}
                                        value={movementDateStart ? movementDateStart : null}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs} adapterLocale='fr'
                                >
                                    <DatePicker
                                        sx={{marginLeft:'.5em'}}
                                        disableFuture={true}
                                        slotProps={{
                                            textField:{
                                                size : 'small',
                                                readOnly : true
                                            }
                                        }}
                                        label="Date de fin"
                                        onChange={(e) => {
                                            setMovementDateEnd(dayjs(e))
                                        }}
                                        value={movementDateEnd ? movementDateEnd : null}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        :
                            null
                    }
                    {
                        filters?.FilterEnStock && (!searchActive && !searchTransitioning) ?
                            <Grid item xs={'auto'}>
                                <FormGroup row>
                                    <CheckAvatar
                                        iconName = 'dolly'
                                        checkedBgColor = '#ed6c02'
                                        handleCheck = {handleCheck}
                                        column = 'FilterEnStock'
                                        value = 'oui'
                                        title = 'En stock'
                                        filters={filters}
                                    />
                                    <CheckAvatar
                                        iconName = 'dolly-empty'
                                        checkedBgColor = '#009ee3'
                                        handleCheck = {handleCheck}
                                        column = 'FilterEnStock'
                                        value = 'non'
                                        title = 'Pas en stock'
                                        filters={filters}
                                    />
                                </FormGroup>
                            </Grid>
                            : null
                    }

                    <Grid item xs={'auto'}>
                        <Search>
                            <StyledInputBase
                                onFocus={(e) => {
                                    setSearchActive(true)
                                }}
                                onBlur={(e) => {
                                    setSearchActive(false)
                                    setSearchTransitioning(true)
                                }}
                                onTransitionEnd={(e) => {
                                    setSearchTransitioning(false)
                                }}

                                placeholder="Rechercher..."
                                inputProps={{'aria-label': 'Rechercher'}}
                                value={freeSearch}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon style={{marginLeft: 10}}/>
                                    </InputAdornment>
                                }
                                endAdornment={
                                        <InputAdornment
                                            position="end"
                                            onClick={(e) => {
                                                setFreeSearch('')
                                            }}
                                            style={{cursor: 'pointer'}}
                                        >
                                            {
                                                !['xs'].includes(size) ?
                                                    <Select
                                                        variant="standard"
                                                        input={<TransparentInputBase />}
                                                        value={searchColumn}
                                                        onChange={(e) => {
                                                            setSearchColumn(e.target.value)}
                                                        }
                                                    >
                                                        <MenuItem value='ALL' key={0}>Toutes les colonnes</MenuItem>
                                                        {
                                                            columns.map((column, idx) => {
                                                                if (
                                                                    (column?.type && column.type !== 'date' && column.type !== 'money') ||
                                                                    column?.hidden
                                                                ){
                                                                    return null;
                                                                } else {
                                                                    return(
                                                                        <MenuItem value={column.id} key={idx+1}>{column.label}</MenuItem>
                                                                    )
                                                                }
                                                            })
                                                        }
                                                    </Select>:
                                                    null
                                            }
                                            {
                                                freeSearch !== '' ?
                                                    <CancelIcon style={{color: 'red', marginRight: 10, marginLeft: 10}} />:
                                                    null
                                            }
                                        </InputAdornment>
                                }
                                onChange={(event) => {
                                    setFreeSearch(event.target.value)
                                }}
                            />
                        </Search>
                    </Grid>
                    {
                        ['xs','sm'].includes(size) ?
                            <Grid item xs={'auto'}>
                                <FormControl
                                    size={'small'}
                                    sx={{
                                        minWidth : '8em'
                                    }}
                                >
                                    <InputLabel id="sorting-label">
                                        Trier selon
                                    </InputLabel>
                                    <Select
                                        labelId={'sorting-label'}
                                        label={'Trier selon'}
                                        value={orderBy}
                                        onChange={(e) => {
                                            setOrderBy(e.target.value);
                                        }}
                                    >
                                        {
                                            columns.map((column, index) => {
                                                if (!column?.hidden && column.id !== 'FreeText' && !['icon'].includes(column.type) ) {
                                                    return (
                                                        <MenuItem value={column.id} key={column.id}>
                                                            {column.label}
                                                        </MenuItem>
                                                    )
                                                }
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <ToggleButtonGroup
                                    sx={{
                                        paddingTop : "1px",
                                        paddingLeft : '.5em'
                                    }}
                                    value={order}
                                    exclusive
                                    onChange={(e, value) => {
                                        if (value) {
                                            setOrder(value)
                                        }
                                    }}
                                    aria-label="Sens du tri"
                                >
                                    <ToggleButton value="asc" aria-label="Croissant">
                                        <FontAwesomeIcon icon={solid('down')}/>
                                    </ToggleButton>
                                    <ToggleButton value="desc" aria-label="Décroissant">
                                        <FontAwesomeIcon icon={solid('up')}/>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            : null
                    }
                </Grid>
                <Box
                    className={notFixed ? '' :"tableFixHead"}
                    sx={notFixed ?
                            {}
                        :
                            {
                                overflow: 'auto',
                                height: height - 170,
                            }
                    }
                >
                    {
                        tableLoading ?
                            skeleton
                        :
                            <Table
                            >
                                <TableHead
                                    sx={notFixed ?
                                        {}
                                        :
                                        {
                                            position: 'sticky',
                                            top: 0,
                                            backgroundColor: 'white',
                                            zIndex: 100,
                                        }
                                    }
                                >
                                    {
                                        ['xs', 'sm'].includes(size) && cardMetaData !== null ?
                                            null
                                            :
                                            <TableRow>
                                                {
                                                    columns.map((column, index) => {
                                                        if ((!column.hasOwnProperty('hidden') || column.hidden === false) && column.id !== 'FreeText') {
                                                            return (
                                                                <TableCell
                                                                    key={index}
                                                                    sortDirection={orderBy === column.id ? order : false}
                                                                    align={column.hasOwnProperty('align') ? column.align : 'left'}
                                                                >
                                                                    <TableSortLabel
                                                                        active={orderBy === column.id}
                                                                        direction={orderBy === column.id ? order : 'asc'}
                                                                        onClick={(event) => {
                                                                            handleRequestSort(event, column.id)
                                                                        }}
                                                                        sx={
                                                                            column.label.substring(0, 2) === 'N°' ?
                                                                                {whiteSpace: 'nowrap'}
                                                                                : null
                                                                        }
                                                                    >
                                                                        {column.label}
                                                                    </TableSortLabel>
                                                                </TableCell>
                                                            )
                                                        }
                                                    })
                                                }
                                            </TableRow>
                                    }
                                </TableHead>
                                <TableBody>
                                    {
                                        stableSort(data, getComparator(order, orderBy)).map((datum, index) => {
                                            if (validateDisplayingData(datum, columns)) {
                                                if (nbColumnNotHidden === 0 && lineForCounting === false) {
                                                    lineForCounting = true
                                                } else {
                                                    lineForCounting = false
                                                }

                                                let xsPrincipal = null
                                                // cas d'un affichage à une colonne
                                                if (['xs', 'sm'].includes(size) && cardMetaData !== null) {
                                                    xsPrincipal = 1
                                                    if (cardMetaData.hasOwnProperty('tel') || cardMetaData.hasOwnProperty('email')) {
                                                        xsPrincipal++
                                                    }
                                                }
                                                return (
                                                    <Fragment key={index}>
                                                        {
                                                            ['xs', 'sm'].includes(size) && cardMetaData !== null ?
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Card>
                                                                            <CardHeader
                                                                                title={
                                                                                    <Typography sx={{fontSize : '1.5em'}}>
                                                                                        {
                                                                                            cardMetaData?.link ?
                                                                                                <Link href={datum[cardMetaData.link]}>
                                                                                                    {datum[cardMetaData.title]}
                                                                                                </Link>
                                                                                                :
                                                                                                datum[cardMetaData.title]
                                                                                        }
                                                                                    </Typography>
                                                                                }
                                                                                subheader={
                                                                                    xsPrincipal === 1 ?
                                                                                        <div
                                                                                            dangerouslySetInnerHTML={{__html: datum[cardMetaData.subheader]}}/>
                                                                                        :
                                                                                        <Grid container>
                                                                                            <Grid item xs={12} sx={{marginY : '.5em'}}>
                                                                                                {datum[cardMetaData.subheader]}
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                {
                                                                                                    cardMetaData.hasOwnProperty('tel') &&
                                                                                                    datum[cardMetaData['tel']] !== null &&
                                                                                                    datum[cardMetaData['tel']].trim() !== '' ?
                                                                                                        <a href={"tel:" + datum[cardMetaData['tel']]}>
                                                                                                            <Chip
                                                                                                                avatar={
                                                                                                                    <Avatar>
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={duotone('mobile')}/>
                                                                                                                    </Avatar>}
                                                                                                                label={datum[cardMetaData['tel']]}
                                                                                                                style={{cursor: 'pointer',marginBottom: '5px'}}
                                                                                                            />
                                                                                                        </a>
                                                                                                        : null
                                                                                                }
                                                                                            </Grid>
                                                                                            <Grid item xs={12}>
                                                                                                {
                                                                                                    cardMetaData.hasOwnProperty('email') &&
                                                                                                    datum[cardMetaData['email']] !== null &&
                                                                                                    datum[cardMetaData['email']].trim() !== '' ?
                                                                                                        <a href={"mailto:" + datum[cardMetaData['email']]}>
                                                                                                            <Chip
                                                                                                                avatar={
                                                                                                                    <Avatar>
                                                                                                                        <FontAwesomeIcon
                                                                                                                            icon={duotone('envelope')}/>
                                                                                                                    </Avatar>}
                                                                                                                label={datum[cardMetaData['email']]}
                                                                                                                style={{cursor: 'pointer'}}
                                                                                                            />
                                                                                                        </a> :
                                                                                                        null
                                                                                                }
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                }
                                                                                avatar={
                                                                                    cardMetaData.hasOwnProperty('avatar') && ['sm','xs'].includes(size) ?
                                                                                        <MakeAvatar icon={datum[cardMetaData.avatar]} /> :
                                                                                        null
                                                                                }
                                                                            />
                                                                            {
                                                                                cardMetaData.hasOwnProperty('content') ?
                                                                                    <CardContent>
                                                                                        <div
                                                                                            dangerouslySetInnerHTML={{__html: reformatDate(datum[cardMetaData['content']])}}/>
                                                                                    </CardContent> :
                                                                                    null
                                                                            }
                                                                            {
                                                                                cardMetaData.hasOwnProperty('action') ?
                                                                                    <CardActions>
                                                                                        {
                                                                                            cardMetaData['action-description'].type === 'button' ?
                                                                                                datum[cardMetaData['action-description']?.id]?.action === 'collapse' && datum[datum[cardMetaData['action-description']?.id]?.data]?.length > 0 ?
                                                                                                    <ToggleCollapseButton
                                                                                                        lineIndex={index}
                                                                                                        collapseOpen={collapseOpen}
                                                                                                        toggleCollapseOpen={toggleCollapseOpen}
                                                                                                    />
                                                                                                    : cardMetaData['action-description']?.id === 'Destock' && cart[datum.WarehouseCode]?.Items[datum.ItemCode] ?
                                                                                                        <QuantityControl
                                                                                                            cart={cart}
                                                                                                            datum={datum}
                                                                                                            handleSetCart={handleSetCart}
                                                                                                        />
                                                                                                        :
                                                                                                        <TableButton
                                                                                                            data={datum}
                                                                                                            type={cardMetaData['action-description'].type}
                                                                                                            action={datum[cardMetaData['action-description']?.id]?.action ? datum[cardMetaData['action-description'].id].action : null}
                                                                                                            label={datum[cardMetaData['action-description']?.id]?.label ? datum[cardMetaData['action-description'].id].label : null}
                                                                                                            style={datum[cardMetaData['action-description']?.id]?.style ? datum[cardMetaData['action-description'].id].style : null}
                                                                                                            title={datum[cardMetaData['action-description']?.id]?.title ? datum[cardMetaData['action-description'].id].title : null}
                                                                                                            forCard={true}
                                                                                                            setAction={setAction}
                                                                                                            dispatch={dispatch}
                                                                                                        />
                                                                                                :
                                                                                                cardMetaData['action-description'].type === 'buttongroup' ?
                                                                                                    <TableButtonGroup
                                                                                                        column={cardMetaData['action-description']}
                                                                                                        data={datum}
                                                                                                        forCard={true}
                                                                                                        setAction={setAction}
                                                                                                        dispatch={dispatch}
                                                                                                    />
                                                                                                    :
                                                                                                    <div>##{datum[cardMetaData['action']].id}##</div>
                                                                                        }
                                                                                    </CardActions>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                datum.hasOwnProperty('CollapseStock') && collapseOpen ?
                                                                                    <CollapseRowStock
                                                                                        index={index}
                                                                                        data={datum}
                                                                                        collapseOpen={collapseOpen}
                                                                                        setAction={setAction}
                                                                                        user={user}
                                                                                        cart={cart}
                                                                                    />
                                                                                    : datum.hasOwnProperty('CollapseReappro') && collapseOpen ?
                                                                                        <CollapseRowReappro
                                                                                            index={index}
                                                                                            data={datum}
                                                                                            collapseOpen={collapseOpen}
                                                                                            setAction={setAction}
                                                                                            user={user}
                                                                                        />
                                                                                        :null
                                                                            }

                                                                        </Card>
                                                                    </TableCell>
                                                                </TableRow>
                                                                :
                                                                <Fragment>
                                                                    <TableRow>
                                                                        {
                                                                            columns.map((column, index2) => {
                                                                                // Analyse des liens
                                                                                let dataToDisplay =
                                                                                    column.type === 'money' ?
                                                                                        datum[column.id].toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ' €'
                                                                                        : column.type === 'date' ?
                                                                                            (datum[column.id] !== null ?
                                                                                                    reformatDate(datum[column.id])
                                                                                                    : ''
                                                                                            )
                                                                                            : datum[column.id]
                                                                                if ((!column.hasOwnProperty('hidden') || column.hidden === false) && column.id !== 'FreeText') {
                                                                                    if (lineForCounting) {
                                                                                        nbColumnNotHidden++;
                                                                                    }
                                                                                    return (
                                                                                        <TableCell
                                                                                            sx={{paddingY:'5px'}}
                                                                                            key={index2}
                                                                                            align={
                                                                                                column.hasOwnProperty('align') ?
                                                                                                    column.align
                                                                                                    : column.id === 'Destock' ?
                                                                                                        'center'
                                                                                                        :
                                                                                                        'left'
                                                                                            }
                                                                                            className={
                                                                                                datum.hasOwnProperty('FreeText') && datum.FreeText === null ?
                                                                                                    classes.cellWithoutComment :
                                                                                                    datum.hasOwnProperty('FreeText') && datum.FreeText !== null ?
                                                                                                        classes.cellWithComment :
                                                                                                        null
                                                                                            }
                                                                                        >
                                                                                            {
                                                                                                column?.type && ['button', 'chip'].includes(column.type) ?
                                                                                                    datum[column?.id]?.action === 'collapse' && datum[datum[column?.id]?.data]?.length > 0 ?
                                                                                                        <ToggleCollapseButton
                                                                                                            lineIndex={index}
                                                                                                            collapseOpen={collapseOpen}
                                                                                                            toggleCollapseOpen={toggleCollapseOpen}
                                                                                                        />
                                                                                                        : column.id === 'Destock' && cart[datum.WarehouseCode]?.Items[datum.ItemCode] ?
                                                                                                            <QuantityControl
                                                                                                                cart={cart}
                                                                                                                datum={datum}
                                                                                                                handleSetCart={handleSetCart}
                                                                                                            />
                                                                                                            :
                                                                                                            <TableButton
                                                                                                                data={datum}
                                                                                                                type={column?.type ? column.type : null}
                                                                                                                action={datum[column?.id]?.action ? datum[column.id].action : null}
                                                                                                                label={datum[column?.id]?.label ? datum[column.id].label : null}
                                                                                                                style={datum[column?.id]?.style ? datum[column.id].style : null}
                                                                                                                title={datum[column?.id]?.title ? datum[column.id].title : null}
                                                                                                                param={datum[column?.id]?.param ? datum[column.id].param : null}
                                                                                                                setAction={setAction}
                                                                                                                dispatch={dispatch}
                                                                                                            />
                                                                                                    : column?.type === 'buttongroup' ?
                                                                                                        <TableButtonGroup
                                                                                                            column={column}
                                                                                                            data={datum}
                                                                                                            setAction={setAction}
                                                                                                            dispatch={dispatch}
                                                                                                        />
                                                                                                        : column?.type === 'icon' && datum[column?.id] ?
                                                                                                            <MakeAvatar
                                                                                                                icon={datum[column?.id]}
                                                                                                                align={column.align}
                                                                                                            />
                                                                                                            : column?.link && datum[column.link] ?
                                                                                                                <Link
                                                                                                                    href={datum[column.link]}
                                                                                                                    underline="none">
                                                                                                                    <span>{dataToDisplay}</span>
                                                                                                                </Link>
                                                                                                                : column?.onclick ?
                                                                                                                    <Link href='#'
                                                                                                                          underline="none">
                                                                                                <span onClick={(e) => {
                                                                                                    setAction(datum[column.onclick],datum)
                                                                                                }}>
                                                                                                    {dataToDisplay}
                                                                                                </span>
                                                                                                                    </Link>
                                                                                                                    : column?.type === 'phone' ?
                                                                                                                        <span style={{whiteSpace : 'nowrap'}}>
                                                                                                {dataToDisplay}
                                                                                            </span>
                                                                                                                        :
                                                                                                                        <span style={{whiteSpace : 'pre-wrap'}}>
                                                                                                {dataToDisplay}
                                                                                            </span>
                                                                                            }
                                                                                        </TableCell>
                                                                                    )
                                                                                }
                                                                            })
                                                                        }
                                                                    </TableRow>
                                                                    {
                                                                        datum.hasOwnProperty('CollapseStock') && collapseOpen ?
                                                                            <TableRow>
                                                                                <TableCell style={{ padding: 0 }} colSpan={50}>
                                                                                    <CollapseRowStock
                                                                                        index={index}
                                                                                        data={datum}
                                                                                        collapseOpen={collapseOpen}
                                                                                        setAction={setAction}
                                                                                        user={user}
                                                                                        cart={cart}
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            : datum.hasOwnProperty('CollapseReappro') && collapseOpen ?
                                                                                <TableRow>
                                                                                    <TableCell style={{ padding: 0 }} colSpan={50}>
                                                                                        <CollapseRowReappro
                                                                                            index={index}
                                                                                            data={datum}
                                                                                            collapseOpen={collapseOpen}
                                                                                            setAction={setAction}
                                                                                            user={user}
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                                :null
                                                                    }
                                                                    {
                                                                        datum.hasOwnProperty('FreeText') ?
                                                                            <TableRow>
                                                                                <TableCell
                                                                                    colSpan={nbColumnNotHidden}
                                                                                    sx={{
                                                                                        whiteSpace: 'pre-wrap',
                                                                                        padding: '0 0 0 16px',
                                                                                        fontSize: '0.7rem',
                                                                                        fontStyle: 'italic',
                                                                                        color: '#666'
                                                                                    }}>
                                                                                    {datum.FreeText}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            :
                                                                            null
                                                                    }
                                                                </Fragment>
                                                        }
                                                    </Fragment>
                                                )
                                            }
                                        })
                                    }
                                </TableBody>
                            </Table>
                    }


                </Box>

            </TableContainer>
        </Box>
    )
}

export const TableSkeleton = ({nbCol= 4,nbLig= 4}) => {
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {
                            [...Array(nbCol).keys()].map(col => {
                                return (
                                    <TableCell key={col}>
                                        <Skeleton width="90%" height={40} />
                                    </TableCell>
                                )
                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        [...Array(nbLig).keys()].map(lig => {
                            return (
                                <TableRow key={lig}>
                                    {
                                        [...Array(nbCol).keys()].map(col => {
                                            return (
                                                <TableCell key={col}>
                                                    <Skeleton width="90%" height={24} />
                                                </TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    textAlign: 'right',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    backgroundColor: alpha('#042C48', 0.4),
    borderRadius: 6,
    '& .MuiInputBase-input': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(1)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '40ch',
            },
        },
    },
}));

const TransparentInputBase = styled(InputBase)(({theme}) => ({
    '& .MuiInputBase-input': {
        backgroundColor: 'transparent',
        transition: 'none',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            '&:focus': {
                width: '100%',
            },
        },
    }
}))

const TableButtonGroup = ({
    column = null,
    data = null,
    forCard= false,
    setAction = null,
    dispatch
}) => {
    return(
        <ButtonGroup>
            {
                data[column.id].map((datum,index) => {
                    return(
                        <TableButton
                            key={index}
                            data={data}
                            type={datum?.type ? datum.type :'button'}
                            action={datum?.action ? datum.action : null}
                            label={datum?.label ? datum.label : null}
                            style={datum?.style ? datum.style : null}
                            title={datum?.title ? datum.title : null}
                            param={datum?.param ? datum.param : null}
                            forCard={forCard}
                            setAction={setAction}
                            dispatch={dispatch}
                        />
                    )
                })
            }
        </ButtonGroup>
    )
}

const TableButton = ({
    data = null,
    type = null,
    action = null,
    label = null,
    style = null,
    title = null,
    param = null,
    setAction = null,
    forCard = false,
    dispatch
}) => {
    const {size} = useViewport()
    return(
        ['xs','sm'].includes(size) && (type === 'link-iframe' || action === 'openSapDoc') ?
            null
        :
            title ?
                <Tooltip title={title ? title : null}>
                    <span>
                        <TableButtonCore
                            data={data}
                            type={type}
                            action={action}
                            label={label}
                            style={style}
                            param={param}
                            forCard={forCard}
                            setAction={setAction}
                            dispatch={dispatch}
                        />
                    </span>
                </Tooltip>
            :
                <TableButtonCore
                    data={data}
                    type={type}
                    action={action}
                    label={label}
                    style={style}
                    param={param}
                    forCard={forCard}
                    setAction={setAction}
                    dispatch={dispatch}
                />
    )
}

const TableButtonCore = ({
    data = null,
    type = null,
    action = null,
    label = null,
    style = null,
    param = null,
    setAction = null,
    forCard = false,
    dispatch
}) => {
    const navigate = useNavigate()
    let onclick = null

    if(action){
        if(action.substring(0,1) === '/'){
            // Cas d'un lien
            onclick = () => {
                navigate(action)
            }
        } else {
            onclick = () => {
                setAction(action,data,param)
            }
        }
    }

    return(
        type === 'link' ?
            <Link href={param} target="_blank">
                <IconButton
                    color={style?.color ? style.color : 'primary'}
                >
                    {
                        style?.icon && style.icon ?
                            <FontAwesomeIcon icon={makeIcon(
                                forCard && style?.iconCard && style.iconCard ? style.iconCard : style.icon
                            )}/>
                            : null
                    }
                </IconButton>
            </Link>
        : type === 'link-iframe' ?
            <IconButton
                color={style?.color ? style.color : 'primary'}
                onClick={() => {
                    dispatch(setPdfReaderUrl(param))
                }}
            >
                {
                    style?.icon && style.icon ?
                        <FontAwesomeIcon icon={makeIcon(
                            forCard && style?.iconCard && style.iconCard ? style.iconCard : style.icon
                        )}/>
                        : null
                }
            </IconButton>
        : type === 'chip' ?
            <ValidateChip
                onClick={onclick}
                label={
                    label ? label : ''
                }
            />
        : label ?
            <Button
                color={style?.color ? style.color : 'primary'}
                startIcon={
                    style?.icon ?
                        <FontAwesomeIcon icon={makeIcon(
                            forCard && style?.iconCard && style.iconCard ? style.iconCard : style.icon
                        )}/>
                        : null
                }
                variant="contained"
                onClick={onclick}
            >
                {label}
            </Button>
        :
            <IconButton
                color={style?.color ? style.color : 'primary'}
                onClick={onclick}
            >
                {
                    style?.iconFg ?
                        <span className="fa-layers">
                            <FontAwesomeIcon icon={makeIcon(
                                forCard && style?.iconCard && style.iconCard ? style.iconCard : style.icon
                            )} />
                                <FontAwesomeIcon
                                    style={{color:'white', transform:'scale(.7,.7) translate(13px,5px)'}}
                                    icon={makeIcon(style.iconFg)}
                                />
                                <FontAwesomeIcon
                                    style={{color:'white', transform:'scale(.7,.7) translate(15px,7px)'}}
                                    icon={makeIcon(style.iconFg)}
                                />
                                <FontAwesomeIcon
                                    style={{transform:'scale(.7,.7) translate(14px,6px)'}}
                                    icon={makeIcon(style.iconFg)}
                                />
                        </span>
                    : style?.icon ?
                        <FontAwesomeIcon icon={makeIcon(
                            forCard && style?.iconCard && style.iconCard ? style.iconCard : style.icon
                        )}/>
                    : null
                }
            </IconButton>
    )
}



const MakeAvatar = ({
    icon = null,
    align = null
}) => {
    let margin =
        align === 'center' ?
            '0 auto 0 0'
        : align === 'right' ?
            '0 0 0 auto'
        :
            '0 auto 0 0'
    return(
        <>
            {
                <Avatar
                    title={icon.title}
                    sx={{
                        fontSize:'1.2em',
                        backgroundColor: icon.color,
                        margin: margin
                    }}>
                    {
                        icon?.icon ?
                            <FontAwesomeIcon
                                icon={makeIcon(icon.icon)}
                                style={{
                                    color: icon?.iconcolor ? icon.iconcolor : 'white',
                                }}
                            />
                        : icon?.text ?
                            icon.text
                        : ''
                    }
                </Avatar>
            }
        </>
    )
}

const ToggleCollapseButton = ({
    lineIndex = null,
    collapseOpen = null,
    toggleCollapseOpen = null
}) => {
    return (
        <IconButton
            onClick={(e) => {
                toggleCollapseOpen(lineIndex)
            }}
        >
            {
                collapseOpen ?
                    <FontAwesomeIcon icon={
                        collapseOpen[lineIndex] ?
                            makeIcon('solid chevron-up')
                            :
                            makeIcon('solid chevron-down')
                    }/>
                    : null
            }
        </IconButton>
    )
}

const CollapseRowStock = ({
    index = null,
    data = null,
    collapseOpen = null,
    setAction = null,
    user=null,
    cart=null,
    dispatch
}) => {
    return (
        <Collapse in={collapseOpen[index]} timeout="auto" unmountOnExit>
            <Grid container justifyContent={'flex-end'} spacing={0}>
                <Grid item xs={'auto'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{borderBottom : 0}}>Numéros de lot</TableCell>
                                <TableCell sx={{borderBottom : 0}}>Date d'entrée</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.CollapseStock.map((datum,index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{borderTop : '1px solid rgba(237, 237, 237, 1)'}}
                                            >
                                                <TableCell sx={{borderBottom : 0}}>
                                                    {datum.BatchId}
                                                </TableCell>
                                                <TableCell sx={{borderBottom : 0}}>
                                                    {reformatDate(datum.Date)}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        </Collapse>
    )
}

const CollapseRowReappro = ({
    index = null,
    data = null,
    collapseOpen = null,
    setAction = null,
    user=null
}) => {
    return (
        <Collapse in={collapseOpen[index]} timeout="auto" unmountOnExit>
            <Grid container justifyContent={'flex-end'} spacing={0}>
                <Grid item xs={12} md={'auto'}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{borderBottom : 0}}>Code</TableCell>
                                <TableCell sx={{borderBottom : 0}}>Désignation</TableCell>
                                <TableCell sx={{borderBottom : 0}}>Quantité</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.Items.map((datum,index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{borderTop : '1px solid rgba(237, 237, 237, 1)'}}
                                            >
                                                <TableCell sx={{borderBottom : 0}}>
                                                    {datum.ItemCode}
                                                </TableCell>
                                                <TableCell sx={{borderBottom : 0}}>
                                                    {datum.ItemName}
                                                </TableCell>
                                                <TableCell sx={{borderBottom : 0}}>
                                                    {datum.Quantity}
                                                </TableCell>

                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>

            </Grid>
        </Collapse>
    )
}

//
const CheckAvatar = ({
    checkedBgColor = null,
    iconName = null,
    iconName2 = null,
    handleCheck = null,
    column = null,
    value = null,
    filters = null,
    title = null,
}) => {
    return (
        <Tooltip title={title}>
            <Checkbox
                checked={filters[column][value]}
                onChange={(e) => {
                    handleCheck({column, value, state : e.target.checked})
                }}
                sx={{padding:'3px'}}
                icon={
                    <Avatar
                        sx={{
                            width: 32,
                            height: 32,
                            fontSize:'.9em',
                            backgroundColor: '#E9E9E9'
                        }}
                    >
                        {
                            !!iconName2 ?
                                <span className="fa-layers">
                                    <FontAwesomeIcon icon={makeIcon('light '+iconName)} />
                                    <FontAwesomeIcon icon={makeIcon('light '+iconName2)} />
                                </span>
                            : !!iconName ?
                                <FontAwesomeIcon icon={makeIcon('light '+iconName)} />
                            :null
                        }
                    </Avatar>
                }
                checkedIcon={
                    <Avatar
                        sx={{
                            width: 32,
                            height: 32,
                            fontSize:'.9em',
                            backgroundColor: checkedBgColor ? checkedBgColor : 'grey'
                        }}
                    >
                        {
                            !!iconName2 ?
                                <span className="fa-layers">
                                    <FontAwesomeIcon icon={makeIcon('solid '+iconName)} />
                                    <FontAwesomeIcon icon={makeIcon('solid '+iconName2)} />
                                </span>
                            : !!iconName ?
                                <FontAwesomeIcon icon={makeIcon('solid '+iconName)} />
                            :null
                        }
                    </Avatar>
                }
            />
        </Tooltip>
    )
}

const QuantityControl = ({
    cart = null,
    datum = null,
    handleSetCart
}) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '8em',
                margin: 'auto'
            }}
        >
            <IconButton
                onClick={(e) => {
                    handleSetCart(datum, null, cart[datum.WarehouseCode]?.Items[datum.ItemCode].Quantity - 1)
                }}
            >
                <FontAwesomeIcon
                    style={{color: '#0288d1'}}
                    icon={regular('square-minus')}
                />
            </IconButton>
            <TextField
                variant={'outlined'}
                size={'small'}
                style={{padding: 0}}
                inputProps={{
                    style: {
                        textAlign: 'center',
                        padding: 0,
                        fontSize: '1em'
                    },
                    inputMode: 'numeric',
                    pattern: '[0-9]*'
                }}
                value={cart[datum.WarehouseCode]?.Items[datum.ItemCode].Quantity}
                onChange={(e) => {
                    handleSetCart(
                        datum,
                        null,
                        e.target.value
                    )
                }}
            />
            <IconButton
                onClick={(e) => {
                    handleSetCart(datum, null, cart[datum.WarehouseCode]?.Items[datum.ItemCode].Quantity + 1)
                }}
            >
                <FontAwesomeIcon
                    style={{color: '#0288d1'}}
                    icon={regular('square-plus')}
                />
            </IconButton>
        </Box>
    )
}

export const CustomPopper = function (props) {
    return <Popper {...props} style={{width: "fit-content"}} placement="bottom-start" />;
};

const makeIcon = (iconText) => {
    switch(iconText){
        case 'solid file':
            return solid('file')
        case 'light file':
            return light('file')
        case 'solid user':
            return solid('user')
        case 'light user':
            return light('user')
        case 'solid file-slash':
            return solid('file-slash')
        case 'light file-slash':
            return light('file-slash')
        case 'solid trash-can':
            return solid('trash-can')
        case 'light trash-can':
            return light('trash-can')
        case 'light bars-progress':
            return light('bars-progress')
        case 'solid bars-progress':
            return solid('bars-progress')
        case 'solid check':
            return solid('check');
        case 'light check':
            return light('check');
        case 'solid lightbulb-on':
            return solid('lightbulb-on');
        case 'solid address-book':
            return solid('address-book');
        case 'solid user-group':
            return solid('user-group');
        case 'solid headset':
            return solid('headset');
        case 'solid globe':
            return solid('globe');
        case 'light pen-to-square':
            return light('pen-to-square')
        case 'solid pen-to-square':
            return solid('pen-to-square')
        case 'solid eye':
            return solid('eye')
        case 'light print':
            return light('print')
        case 'solid print':
            return solid('print')
        case 'solid file-plus':
            return solid('file-plus')
        case 'solid file-pen':
            return solid('file-pen')
        case 'solid toggle-large-on' :
            return solid('toggle-large-on')
        case 'solid list-check' :
            return solid('list-check')
        case 'light memo' :
            return light('memo')
        case 'solid memo' :
            return solid('memo')
        case 'solid arrow-up-right-from-square' :
            return solid('arrow-up-right-from-square')
        case 'solid arrow-down-to-square' :
            return solid('arrow-down-to-square')
        case 'solid chevron-up' :
            return solid('chevron-up')
        case 'solid chevron-down' :
            return solid('chevron-down')
        case 'solid arrow-down-to-line' :
            return solid('arrow-down-to-line')
        case 'solid screwdriver-wrench' :
            return solid('screwdriver-wrench')
        case 'solid user-gear' :
            return solid('user-gear')
        case 'solid user-slash' :
            return solid('user-slash')
        case 'light user-slash' :
            return light('user-slash')
        case 'solid user' :
            return solid('user')
        case 'light user' :
            return light('user')
        case 'solid paperclip' :
            return solid('paperclip')
        case 'solid box-taped' :
            return solid('box-taped')
        case 'solid hundred-points' :
            return solid('hundred-points')
        case 'light hundred-points' :
            return light('hundred-points')
        case 'solid file-invoice' :
            return solid('file-invoice')
        case 'light file-invoice' :
            return light('file-invoice')
        case 'solid dolly' :
            return solid('dolly')
        case 'light dolly' :
            return light('dolly')
        case 'solid dolly-empty' :
            return solid('dolly-empty')
        case 'light dolly-empty' :
            return light('dolly-empty')
        case 'solid triangle-person-digging' :
            return solid('triangle-person-digging')
        case 'solid xmark' :
            return solid('xmark')
        case 'light xmark' :
            return light('xmark')
        case 'solid ban' :
            return solid('ban')
        case 'light ban' :
            return light('ban')
        case 'solid person-dolly-empty' :
            return solid('person-dolly-empty')
        case 'solid square-terminal' :
            return solid('square-terminal')
        case 'solid magnifying-glass' :
            return solid('magnifying-glass')
        default:
            return solid('question');
    }
}