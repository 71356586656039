import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivateRoute} from './PrivateRoute'
import OauthTokenReader from "./authentication/OauthTokenReader";
import Home from "./pages/Home";
import {PageLoader} from "./PageLoader";
import Login from "./authentication/Login"
import {connect} from "react-redux";
import Stock from "./pages/Stock";
import StockDate from "./pages/StockDate";
import {createTheme, ThemeProvider} from "@mui/material";
import Item from "./pages/Item";
import MovementsDate from "./pages/MovementsDate";

const theme = createTheme({
    typography: {
        fontFamily: [
            'Work Sans',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
});

export const App = ({
    loading= false,
    checkingAppCredentials= true,
    user= null
}) => {
    const publicRoutes = [
        'login',
        'init-password',
    ]

    return (
        <ThemeProvider theme={theme}>
            <PageLoader pageLoading={loading} caption="Traitement en cours..."/>
            <OauthTokenReader />
            <BrowserRouter>
                {
                    !checkingAppCredentials
                        ?
                        <Routes>
                            <Route element={<PrivateRoute user={user} publicRoutes={publicRoutes}/>}>
                                <Route path="/" element={<Home/>}/>
                                <Route path="/home" element={<Home/>}/>
                                <Route path="/login" element={<Login/>}/>
                                <Route path="/init-password/:token" element={<Login/>}/>
                                <Route path="/stock" element={<Stock />} />
                                <Route path="/stock-date" element={<StockDate />} />
                                <Route path="/item/:encodedItemCode" element={<Item />} />
                                <Route path="/movements-date" element={<MovementsDate />} />
                            </Route>
                        </Routes>
                        :
                        <PageLoader pageLoading={checkingAppCredentials} caption="Authentification en cours..."/>
                }
            </BrowserRouter>
        </ThemeProvider>
    )
};

export default connect(state => ({
    loading: state.loginReducer.loading,
    checkingAppCredentials: state.loginReducer.checkingAppCredentials,
    user: state.loginReducer.user
}))(App)
