import React, {useState} from "react";
import {Avatar, Card, CardHeader, Grid, ListItemIcon, ListItemText, Menu, MenuItem} from "@mui/material";
import {Person as UserIcon, Logout as LogoutIcon, Menu as MenuIcon} from '@mui/icons-material'
import {disconnectUserImpersonatate, logout} from "./authentication/Login";
import {Link, useNavigate} from "react-router-dom";
import MenuVertical from "./MenuVertical";
import {useViewport} from "./hooks/ViewportContext";
import Typography from "@mui/material/Typography";

export const Header = ({
    user = null,
    userImpersonator = null,
    pages = [],
    dispatch
}) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState(null);
    const [openUser,setOpenUser] = useState(false)

    const setOpenUserClick = (event) => {
        if(openUser){
            setAnchorEl(null);
            setOpenUser(false)
        } else {
            setAnchorEl(event.currentTarget);
            setOpenUser(true)
        }
    }
    const handleClose = () => {
        setAnchorEl(null);
        setOpenUser(false)
    };

    const {size} = useViewport()

    return(
        <Grid item xs={12} style={{backgroundColor: '#FFFFFF', height: 70, borderBottom:'2px solid #0F3380'}} id="pageHeader">
            <Grid container alignItems={"center"} justifyContent={'space-between'}>
                <Grid item xs={'auto'}>
                    <img src="/img/bruneelHeadLogo.jpeg" style={{height: 60, marginTop: 4}} alt="Innoclair" />
                </Grid>
                <Grid item xs sx={{'textAlign':'center'}}>
                    <Typography
                        variant={'h3'}
                        sx={{
                            color : '#162E6B',
                            fontWeight : '500'
                        }}
                    >
                        Portail VMS
                    </Typography>
                </Grid>
                {
                    user !== null ?
                        <Grid item style={{marginTop: 4, marginRight: 20}}>
                            <Avatar>
                                {
                                    ['xs','sm'].includes(size) ?
                                        <MenuIcon style={{fill: '#ffffff'}} onClick={setOpenUserClick} />:
                                        <UserIcon style={{fill: '#ffffff'}} onClick={setOpenUserClick} />
                                }
                            </Avatar>
                            <Menu
                                anchorEl={anchorEl}
                                open={openUser}
                                onClose={handleClose}
                            >
                                <Card style={{marginBottom: 10}}>
                                    <CardHeader
                                        avatar={<Avatar sx={{bgcolor: '#022B49',color: '#ffffff'}}>
                                                    <UserIcon style={{fill: '#ffffff'}} />
                                                </Avatar>}
                                        title={user.cardName !== '' ? user.cardName: user.lastName}
                                        subheader={user.cardName !== '' ? user.firstName + ' '+ user.lastName: user.firstName}
                                        />
                                </Card>

                                {/*<Link*/}
                                {/*    to="/mon-compte"*/}
                                {/*    style={{*/}
                                {/*        textDecoration: 'none',*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <MenuItem sx={{ marginLeft : '9px'}}>*/}
                                {/*        <ListItemIcon>*/}
                                {/*            <UserIcon/>*/}
                                {/*        </ListItemIcon>*/}
                                {/*        <ListItemText>*/}
                                {/*           Mon compte*/}
                                {/*        </ListItemText>*/}
                                {/*    </MenuItem>*/}
                                {/*</Link>*/}
                                {
                                    ['xs','sm'].includes(size) ?
                                    <MenuVertical
                                        pages={pages}
                                        user={user}
                                        dispatch={dispatch}
                                        position="hamburgermenu"
                                    />
                                    :
                                    null
                                }
                                <MenuItem
                                    sx={{ marginLeft : '9px'}}
                                    onClick={(e) => {
                                        if(userImpersonator !== null){
                                            localStorage.removeItem('switch_user')
                                            dispatch(disconnectUserImpersonatate())
                                            navigate('/home')
                                        } else {
                                            dispatch(logout())
                                        }
                                    }}
                                >
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText>
                                        Déconnexion
                                    </ListItemText>
                                </MenuItem>
                            </Menu>
                        </Grid>:
                        null
                }
            </Grid>
        </Grid>
    )
}
