import React, {useEffect, useState, Fragment} from "react"
import {connect} from "react-redux";
import PageSkeleton, {setMessageInformation} from "../PageSkeleton";
import {useLocation} from "react-router-dom";
import {apiGet, apiPost} from "../authentication/OAuth2Utils";
import {
    Box,
    Grid,
    Stack,
} from "@mui/material";
import {TableData, TableSkeleton} from "../TableUtils";
import {useViewport} from "../hooks/ViewportContext";
import dayjs from "dayjs";

const MovementsDate = ({
    user = null,
    userImpersonator = null,
    loading = false,
    caption = '',
    partnerCode = null,
    dispatch
}) => {
    const [movementsLoading,setMovementsLoading] = useState(false)
    const [movements, setMovements] = useState(null)
    const [movementDateStart, setMovementDateStart] = useState(dayjs().startOf('month'))
    const [movementDateEnd, setMovementDateEnd] = useState(dayjs())

    const location = useLocation()
    const uri = location.pathname.substring(1)
    const {width, height, size} = useViewport()
    const controller = new AbortController();

    const fetchData = () => {
        dispatch(setMessageInformation(null))
        if (movementsLoading){
            controller.abort()
        }
        const signal = controller.signal
        setMovementsLoading(true)
        apiPost(
            '/api/get-movements-date',
            {
                'start' : movementDateStart.format('YYYY-MM-DD'),
                'end' : movementDateEnd.format('YYYY-MM-DD'),
                partnerCode,
            },
            0,
            signal
        )
            .then(data => {
                if(data.status === 'error'){
                    dispatch(setMessageInformation({
                        severity: 'error',
                        title: 'Une erreur est survenue',
                        content: data.message
                    }))
                } else {
                    setMovements(data.data)
                }
            })
            .finally(() => {
                setMovementsLoading(false)
            })
    }

    useEffect(() => {
        if (movementDateStart.isValid() && movementDateEnd.isValid()) {
            fetchData()
        }
    }, [movementDateStart, movementDateEnd, partnerCode]);

    return(
        <>
        <PageSkeleton
            user={user}
            userImpersonator={userImpersonator}
            title={'Mouvements date à date'}
            page_active={uri}
            loading={loading}
            caption={caption}
            dispatch={dispatch}
        >
            <Stack
                direction={['xs','sm'].includes(size) ? 'column' : 'row'}
                sx={
                    ['xs','sm'].includes(size) ?
                        {
                            display:'flex',
                            flexDirection:'column',
                            height : height - (125),
                            maxHeight : height - (125)
                        }
                    :
                        {display:'flex'}
                }
            >
                <Box
                    sx={
                        ['xs','sm'].includes(size) ?
                            {
                                flexBasis:'auto',
                                flexGrow:'0',
                                flexShrink:'3',
                                overflow:'auto'
                            }
                        :
                            {
                                overflow: 'auto',
                                height: height - 125,
                                flexBasis: '100%'
                            }
                    }
                >
                    {
                        movements ?
                            <Grid container>
                                <Grid item xs={12}>
                                    <TableData
                                        columns={movements.entete}
                                        data={movements.data}
                                        defaultOrder='asc'
                                        defaultOrderColumnId='DocDate'
                                        loading={loading}
                                        caption={caption}
                                        dispatch={dispatch}
                                        user={user}
                                        notFixed={true}
                                        page_active={location.pathname.substring(1)}
                                        movementDateStart={movementDateStart}
                                        setMovementDateStart={setMovementDateStart}
                                        movementDateEnd={movementDateEnd}
                                        setMovementDateEnd={setMovementDateEnd}
                                        csvExportInfo={{
                                            url : 'download-csv/movements-date',
                                            fileName :'Mouvements'+movementDateStart.format(' DD-MM-YYYY')+'_'+movementDateEnd.format(' DD-MM-YYYY')+'.csv',
                                            params : {
                                                start : movementDateStart.format('YYYY-MM-DD'),
                                                end : movementDateEnd.format('YYYY-MM-DD')
                                            }
                                        }}
                                        tableLoading={movementsLoading}
                                        skeleton={<TableSkeleton nbCol={5} nbLig={10} />}
                                        partnerCode={partnerCode}
                                    ></TableData>
                                </Grid>
                            </Grid>
                        :
                            <TableSkeleton nbCol={5} nbLig={10} />
                }
                </Box>
            </Stack>
        </PageSkeleton>
    </>
    )
}
export default connect(
    state => ({
        user: state.loginReducer.user,
        userImpersonator: state.loginReducer.userImpersonator,
        loading: state.pageLoaderReducer.pageLoading,
        caption: state.pageLoaderReducer.caption,
        partnerCode: state.partnerReducer.partnerCode,
    }),
)(MovementsDate)
