import React, {Fragment, useEffect, useState} from "react"
import {createSlice} from "@reduxjs/toolkit";
import {apiGet} from "./authentication/OAuth2Utils";
import {Autocomplete, TextField} from "@mui/material";
import {CustomPopper} from "./TableUtils";
import {connect} from "react-redux";

export const PartnerSlice = createSlice({
    name: 'partnerManager',
    initialState: {
        partnerCode: null,
        partners:null
    },
    reducers: {
        setPartnerCode: (state, action) => {
            return {
                ...state,
                partnerCode: action.payload
            }
        },
        setPartners: (state, action) => {
            return {
                ...state,
                partners: action.payload
            }
        }
    }
})

export const {actions,reducer} = PartnerSlice

export const {
    setPartnerCode,
    setPartners
} = actions

const Partners = ({
    dispatch,
    partnerCode,
    partners,
    user,
}) => {
    const initPartners = () => {
        apiGet('/api/get-partners')
        .then(data => {
            dispatch(setPartners(data.data))
        })
    }

    useEffect(() => {
        initPartners()
    },[])

    return (
        <Autocomplete
            PopperComponent={CustomPopper}
            options={partners ? partners : ['Chargement en cours...']}
            renderInput={(params) =>
                <TextField
                    {...params}
                    fullWidth={true}
                    sx={{minWidth: '250px'}}
                    disabled={!partners}
                    label="Client"
                    variant={'standard'}
                />
            }
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={
                !!partnerCode && partners && partners.find(o => o.id === partnerCode) ?
                    partners.find(o => o.id === partnerCode)
                :
                    !!user?.cardCode && partners && partners.find(o => o.id === user.cardCode) ?
                        partners.find(o => o.id === user.cardCode)
                    :
                        null
            }
            onChange={(event, newValue) => {
                dispatch(setPartnerCode(newValue.id))
            }}
        />
    )
}

export default connect(
    state => ({
        user: state.loginReducer.user,
        partnerCode: state.partnerReducer.partnerCode,
        partners: state.partnerReducer.partners,
    }),
)(Partners)