import React, {useState} from "react";
import {createSlice} from "@reduxjs/toolkit";
import {anonymousApiPost, authenticate} from "./OAuth2Utils";
import {fetchToken} from "./OauthTokenReader";
import {connect} from "react-redux";
import {Alert, Box, Button, Collapse, Grid, Paper, TextField} from "@mui/material";
import {useLocation, useParams} from "react-router-dom";
import {Header} from "../Header";
import {useViewport} from "../hooks/ViewportContext";

export const initialState = {
    login: '',
    password: '',
    loading: false,
    loaded: false,
    user: null,
    userImpersonator: null,
    checkingAppCredentials : true,
    referer : '',
};

export const loginSlice = createSlice({
    name: 'login',
    initialState: initialState,
    reducers: {
        setLogin: (state, action) => ({
            ...state,
            login: action.payload
        }),
        setReferer: (state, action) => ({
            ...state,
            referer: action.payload
        }),
        setPassword: (state, action) => ({
            ...state,
            password: action.payload
        }),
        setLoading: (state, action) => ({
            ...state,
            loading: action.payload,
            loaded: action.payload ? false : state.loaded
        }),
        setLoaded: (state, action) => ({
            ...state,
            loaded: action.payload
        }),
        setUser: (state, action) => {
            return({
                ...state,
                user: action.payload,
                checkingAppCredentials: false
            })
        },
        setUserImpersonator: (state, action) => {
            return ({
                ...state,
                userImpersonator: state.user,
                user: action.payload
            })
        },
        disconnectUserImpersonatate: (state, action) => {
            return ({
                ...state,
                user: state.userImpersonator,
                userImpersonator: null
            })
        },
        setCheckingAppCredentials: (state, action) => ({
            ...state,
            checkingAppCredentials: action.payload
        })
    }
});

export const {actions, reducer} = loginSlice;

export const {
    setLogin,
    setPassword,
    setLoading,
    setLoaded,
    setReferer,
    setUser,
    setUserImpersonator,
    disconnectUserImpersonatate,
    setCheckingAppCredentials
} = actions;

export const sendLogin = ({login, password, setError}) => dispatch => {
    dispatch(setLoading(true));
    authenticate({login, password})
        .then(data => {
            if (data.hasOwnProperty('access_token') && data.access_token !== '') {
                localStorage.setItem('access_token', data.access_token);
                localStorage.setItem('refresh_token', data.refresh_token);
                dispatch(fetchToken());
            }
        })
        .catch((e) => {
            console.log(e)
            setError('Le login ou le mot de passe est incorrect')
        })
        .finally(() => {
            dispatch(setLoading(false));
        })
    ;
};

export const sendInitPassword = ({password1,password2,token,setError}) => dispatch => {
    if(password1 !== password2){
        setError('Les mots de passe ne sont pas identiques');
    } else {
        dispatch(setLoading(true));
        let params = {
            password: password1,
            token
        }
        anonymousApiPost('/anonymous-api/set-user-password',params)
            .then(data => {
                if(data.status === 'success'){
                    localStorage.removeItem('switch_user')
                    window.location = '/login'
                } else if(data.status === 'error') {
                    setError(data.message)
                }
            })
            .catch(e => {
                console.log(e)
                setError('Erreur détectée : ' + e.error)
            })
            .finally(() => {
                dispatch(setLoading(false))
            })
    }
}

export const logout = () => dispatch => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('switch_user')
    window.location.pathname = '/login';
};

export const Login = ({
    login = '',
    password = '',
    dispatch,
    ...rest
}) => {
    const location = useLocation()

    const explodedPathName = location.pathname.split('/')
    const currentRoute = explodedPathName[1]

    let params = useParams()

    const [error,setError] = useState('')

    const {width, height, size} = useViewport()

    return(
        <Grid container style={{width: '100%'}}>
            <Header />
            <Box
                style={{
                    backgroundImage: 'url(/img/bruneelAccueil.webp)',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    animation: 'headerAppearence 0.75s forwards',
                    position: 'fixed',
                    top:50,
                    zIndex:-1,
                    width : width,
                    minHeight : height
                }}
            >
            </Box>
            <Box
                component={Paper}
                style={{
                    animation: 'loginAppearence 1.5s forwards',
                    margin: 'auto',
                    padding: 20,
                    marginTop: '22%'
                }}
            >
                {
                    (() => {
                        //console.log(location)
                        switch (currentRoute) {
                            case 'login':
                                return (
                                    <LoginForm
                                        login={login}
                                        error={error}
                                        setError={setError}
                                        password={password}
                                        dispatch={dispatch}
                                    />
                                )
                            case 'init-password':
                                return(
                                    <InitPasswordForm
                                        token={params.token}
                                        error={error}
                                        setError={setError}
                                        dispatch={dispatch}
                                    />
                                )
                            default:
                                return(null)
                        }
                    })()
                }
            </Box>
        </Grid>
    );
}

const LoginForm = ({
    login = '',
    password = '',
    error = '',
    setError = null,
    dispatch
}) => {
    return(
        <Grid container spacing={2} style={{maxWidth: 400}}>
            {
                error !== '' ?
                    <Grid item xs={12}>
                        <Alert severity="error">{error}</Alert>
                    </Grid>:
                    null
            }
            <Grid item xs={12}>
                <TextField
                    label="Login"
                    value={login}
                    fullWidth={true}
                    onChange={(e) => {
                        dispatch(setLogin(e.target.value))
                    }}
                    onKeyUp={(e) => {
                        if (e.key.toUpperCase() === 'ENTER') {
                            document.getElementById('password_input').focus();
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="password_input"
                    label="Password"
                    type="password"
                    value={password}
                    fullWidth={true}
                    onChange={(e) => {
                        dispatch(setPassword(e.target.value))
                    }}
                    onKeyUp={(e) => {
                        if (e.key.toUpperCase() === 'ENTER') {
                            dispatch(sendLogin({login, password, setError}))
                        }
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => {
                        dispatch(sendLogin({login, password, setError}))
                    }}
                >
                    Connexion
                </Button>
            </Grid>
        </Grid>
    )
}

const InitPasswordForm = ({
    token='',
    error = '',
    setError = null,
    dispatch
}) => {
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')

    return(
        <Grid container spacing={4} style={{maxWidth: 600}}>
            <Grid item xs={12}>
                <Alert severity="info">
                    Veuillez renseigner votre mot de passe.<br />
                    Attention, une fois validé, il vous sera demandé de vous identifier avec votre email et ce mot de passe.
                </Alert>
            </Grid>
            <Grid item xs={12} style={error === '' ? {paddingTop: 0}:null}>
                <Collapse in={error !== ''} orientation="vertical">
                    <Alert severity="error">{error}</Alert>
                </Collapse>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Password"
                    value={password1}
                    type="password"
                    fullWidth={true}
                    onChange={(e) => {
                        setError('')
                        setPassword1(e.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Password à nouveau"
                    type="password"
                    value={password2}
                    fullWidth={true}
                    onChange={(e) => {
                        setError('')
                        setPassword2(e.target.value)
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    fullWidth={true}
                    onClick={(e) => {
                        dispatch(sendInitPassword({password1, password2, token, setError}))
                    }}
                >
                    Enregistrer mon mot de passe
                </Button>
            </Grid>
        </Grid>
    )
}

export default connect(
    state => ({
        login : state.loginReducer.login,
        password : state.loginReducer.password
    }),
)(Login)
